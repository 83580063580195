var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("select-suggestion", {
        staticClass: "flex-grow w-full",
        attrs: {
          model: "DynamicReport",
          label: _vm.$t("relatorio_dinamico"),
          max: 10,
          itemTextFormat: function (model) {
            return model.id + " - " + model.name
          },
          color: "dark",
          column: "name",
          appendClearOption: true,
          promptText: _vm.$t("informe-o-nome-para-pesquisar"),
          placeholderText: _vm.$t("digite-o-nome-do-relatorio"),
        },
        on: { "selected-model": _vm.onReportSelected },
        model: {
          value: _vm.selectedReport,
          callback: function ($$v) {
            _vm.selectedReport = $$v
          },
          expression: "selectedReport",
        },
      }),
      _vm.selectedReportDetails
        ? _c("vs-divider", { staticClass: "mt-6" }, [
            _vm._v(_vm._s(_vm.$t("defina_os_filtros"))),
          ])
        : _vm._e(),
      _vm.selectedReportDetails && _vm.selectedReportDetails.filters.length > 0
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              { staticClass: "grid grid-cols-2 gap-4" },
              _vm._l(
                _vm.selectedReportDetails.filters,
                function (filter, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "mb-4" },
                    [
                      filter.type === "date" ||
                      filter.type === "datetime" ||
                      filter.type === "boolean"
                        ? _c(
                            "label",
                            {
                              staticClass: "block mb-1",
                              attrs: { for: filter.field },
                            },
                            [_vm._v(" " + _vm._s(filter.label) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        _vm.getComponentForType(filter.type),
                        _vm._b(
                          {
                            tag: "component",
                            class: {
                              "w-full": filter.type !== "boolean",
                              required: filter.required,
                            },
                            attrs: {
                              label:
                                filter.type !== "date" &&
                                filter.type !== "datetime"
                                  ? filter.label
                                  : null,
                            },
                            model: {
                              value: _vm.filterValues[filter.field],
                              callback: function ($$v) {
                                _vm.$set(_vm.filterValues, filter.field, $$v)
                              },
                              expression: "filterValues[filter.field]",
                            },
                          },
                          "component",
                          _vm.getComponentProps(filter),
                          false
                        )
                      ),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-full flex gap-2 justify-end" },
        [
          _vm.selectedReportDetails
            ? _c(
                "vs-button",
                {
                  staticClass: "mt-4",
                  attrs: { color: "primary" },
                  on: { click: _vm.generateReport },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("gerar_relatorios_dinamicos")) + " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }